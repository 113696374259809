/*
    ----------
*/
$Font : (
    "Default"       : "Inter"
);
/* 
    ----------
*/
$Size : (
    "10" : 10px,
    "11" : 11px,
    "12" : 12px,
    "13" : 13px,
    "14" : 14px,
    "15" : 15px,
    "16" : 16px,
    "17" : 17px,
    "18" : 18px,
    "19" : 19px,
    "20" : 20px
);
/* 
    ---------- 
*/
$Color : (
    "Default" : #EA1917,
    "White"   : #FFFFFF,
    "Black"   : #000000,
    "Grey"    : #333333,
    "Light"   : #F4F8FA
);
/* 
    ----------
*/
.dE5574{ font-family: map-get( $Font, "Default" ) }
/* 
    ----------
*/
.S6pt64{ float: left  }
.yJ452A{ float: right }
.eTt257{ float: none  }
/* 
    ----------
*/
.q7h2M9{ width: 25%  }
.S09r6B{ width: 50%  }
.b7P443{ width: 75%  }
.C96Q4r{ width: 100% }
/* 
    ----------
*/
.eU7H63{ font-size: map-get( $Size, "10" ) }
.S7gw68{ font-size: map-get( $Size, "11" ) }
.iI0402{ font-size: map-get( $Size, "12" ) }
.xG0j09{ font-size: map-get( $Size, "13" ) }
.PlO170{ font-size: map-get( $Size, "14" ) }
.wI860z{ font-size: map-get( $Size, "15" ) }
.Wm8G06{ font-size: map-get( $Size, "16" ) }
.bS8S40{ font-size: map-get( $Size, "17" ) }
.s4U1j1{ font-size: map-get( $Size, "18" ) }
.J589Ta{ font-size: map-get( $Size, "19" ) }
.i260Up{ font-size: map-get( $Size, "20" ) }
/* 
    ----------
*/
.C2y88Q{ font-weight: lighter }
.u37E41{ font-weight: normal  }
.Y4674t{ font-weight: bold    }
.zK3920{ font-weight: bolder  }
/* 
    ----------
*/
.F2n16X{ font-weight: 100 }
.Y8k86e{ font-weight: 200 }
.y3Or88{ font-weight: 300 }
.nS533w{ font-weight: 400 }
.K0cW96{ font-weight: 500 } 
.D933nr{ font-weight: 600 }
.jS8601{ font-weight: 700 }
.Zv29i2{ font-weight: 800 }
.Is1259{ font-weight: 900 }
/* 
    ----------
*/
.J284Xb{ font-style: normal  }
.Ep1U39{ font-style: oblique }
.R6t03r{ font-style: italic  }
/* 
    ----------
*/
.v6EU83{ color: map-get( $Color, "Default" ) }
.M99v3q{ color: map-get( $Color, "White" )   }
.vOx602{ color: map-get( $Color, "Black" )   }
.Y0u334{ color: map-get( $Color, "Grey" )    }
.r4K8f4{ color: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.cvC365{ fill: map-get( $Color, "Default" ) }
.f8T6I8{ fill: map-get( $Color, "White" )   }
.sRU313{ fill: map-get( $Color, "Black" )   }
.Vr4196{ fill: map-get( $Color, "Grey" )    }
.b29YY3{ fill: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.u0Bc59{ background: map-get( $Color, "Default" ) }
.rP408N{ background: map-get( $Color, "White" )   }
.u3I753{ background: map-get( $Color, "Black" )   }
.i3eE58{ background: map-get( $Color, "Grey" )    }
.gZ959C{ background: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.wDC543{ display: inline-block }
.eF429z{ display: inline-flex  }
.xA723K{ display: table-cell   }
.X35k4K{ display: table-row    }
.bN2p30{ display: inline       }
.S0u16d{ display: flex         }
.V81zg1{ display: block        }
.oi8E43{ display: grid         }
.zD661i{ display: table        }
.t3c0N7{ display: -webkit-box  }
.hPP928{ display: none         }
.s6D202{ display: list-item    }
/* 
    ----------
*/
.fE0d48{ flex-direction: column         }
.L640Ti{ flex-direction: column-reverse }
.Y065Fd{ flex-direction: row-reverse    }
.fr598R{ flex-direction: row            }
/* 
    ----------
*/
.ZH339f{ justify-content: flex-start    }
.U080Kf{ justify-content: flex-end      }
.vR05U6{ justify-content: center        }
.g1aS48{ justify-content: space-between }
.h18Ea4{ justify-content: space-around  }
.aR51Y0{ justify-content: space-evenly  }
/* 
    ----------
*/
.I4t79h{ align-items: normal     }
.nA9305{ align-items: stretch    }
.d721Vx{ align-items: center     }
.Q3n44O{ align-items: flex-start }
.J7l0G2{ align-items: flex-end   }
.W44s9p{ align-items: start      }
.C6u87n{ align-items: end        }
.Q7Ae62{ align-items: baseline   }
/* 
    ----------
*/
.xD280K{ text-align: start   }
.Q606yt{ text-align: end     }
.vC9r15{ text-align: center  }
.yyO233{ text-align: justify }
/* 
    ----------
*/
.uUG847{ text-transform: lowercase  }
.GzJ197{ text-transform: uppercase  }
.cMq044{ text-transform: capitalize }
.e2M03y{ text-transform: none       }
/*
    ----------
*/
.vF5k47{ text-decoration: none         }
.F83vw7{ text-decoration: dashed       }
.a20K95{ text-decoration: dotted       }
.C71Dm7{ text-decoration: double       }
.El6174{ text-decoration: solid        }
.r9zX81{ text-decoration: overline     }
.sB248t{ text-decoration: wavy         }
.Q3s2S1{ text-decoration: underline    }
.h6T64Y{ text-decoration: line-through }
/*
    ----------
*/
.cB3332{ text-underline-offset: 7px }
/*
   ----------
*/
.q8K60t{ text-overflow: ellipsis }
.faJ087{ text-overflow: clip     }
.m1BW70{ text-overflow: unset    }
/*
    ----------
*/
.U596Kr{ vertical-align: baseline    }
.t0G834{ vertical-align: sub         }
.ebZ371{ vertical-align: super       }
.I7e03O{ vertical-align: top         }
.j844Eb{ vertical-align: text-top    }
.eQ738Y{ vertical-align: middle      }
.E0vm38{ vertical-align: bottom      }
.z56Q50{ vertical-align: text-bottom }
/*
    ----------
*/
.gL0474{ white-space: normal }
.wqA166{ white-space: nowrap }
.Q7a1D3{ white-space: pre    }
/* 
    ----------
*/
.cH8711{ position: relative }
.gBt584{ position: absolute }
.p9V85g{ position: sticky   }
.uK7E77{ position: static   }
.Ude336{ position: fixed    }
/*
    ----------
*/
.x35Ac2{ overflow: visible }
.J057Si{ overflow: hidden  }
.jC732U{ overflow: scroll  }
.L9Rm29{ overflow: auto    }
/*
    ----------
*/
.n2813w{ visibility: hidden }
.i56yn1{ visibility: visible }
/*
    ----------
*/
.yL0811{ cursor: alias         }
.v6CN61{ cursor: all-scroll    }
.qE800M{ cursor: auto          }
.tJ204O{ cursor: cell          }
.sDN443{ cursor: col-resize    }
.p8C19l{ cursor: context-menu  }
.E0m26B{ cursor: copy          }
.L567nt{ cursor: crosshair     }
.Ri032C{ cursor: default       }
.kD889h{ cursor: e-resize      }
.S6u0h5{ cursor: ew-resize     }
.c2Q91N{ cursor: grab          }
.dZ9M00{ cursor: grabbing      }
.gM266u{ cursor: help          }
.dP797B{ cursor: move          }
.j3Uh65{ cursor: n-resize      }
.cZ180w{ cursor: ne-resize     }
.aH3174{ cursor: nw-resize     }
.j53Z9D{ cursor: nwse-resize   }
.Gp2446{ cursor: no-drop       }
.Lr9809{ cursor: not-allowed   }
.N4g4m6{ cursor: pointer       }
.k6G6h5{ cursor: progress      }
.Yri682{ cursor: row-resize    }
.Zd4o45{ cursor: s-resize      }
.X2R6j8{ cursor: se-resize     }
.p2M9c8{ cursor: sw-resize     }
.Xw0F20{ cursor: text          }
.Q6j5W4{ cursor: vertical-text }
.S344z0{ cursor: w-resize      }
.z9V38t{ cursor: wait          }
.Ac102a{ cursor: zoom-in       }
.d6F48n{ cursor: zoom-out      }
.h30X35{ cursor: none          }
/*
    ----------
*/
.Kg80G9{ word-wrap: normal     }
.uQ136M{ word-wrap: break-word }
/*
    ----------
*/
.z9X51G{ pointer-events: none }
.Bj1642{ pointer-events: auto }
/*
    ----------
*/
.oQ637t{ border-style: dotted }
.r380oV{ border-style: dashed }
.F5m883{ border-style: solid  }
.Fb292w{ border-style: double }
.Fcx262{ border-style: groove }
.eS845b{ border-style: ridge  }
.L2sq24{ border-style: inset  }
.t3KL23{ border-style: outset }
.Z7o516{ border-style: none   }
.Wx078J{ border-style: hidden }
/*
    ----------
*/
.ob2A34{ border:        0 }
.xq62J3{ border-top:    0 }
.PvN976{ border-bottom: 0 }
.rJ26U8{ border-left:   0 }
.LjJ561{ border-right:  0 }
/*
    ----------
*/
.L72y8b{ border-collapse: collapse }
.Z5y7o1{ border-collapse: separate }
/*
    ----------
*/
.m9X7C5{ margin: 0 auto }
/*
    ----------
*/
.pEO064{ margin:        0 }
.vSV800{ margin-left:   0 }
.D797aR{ margin-right:  0 }
.sX6873{ margin-top:    0 }
.B2d81S{ margin-bottom: 0 }
/*
    ----------
*/
.a527eB{ padding:        0 }
.z8o5S4{ padding-top:    0 }
.dW8M00{ padding-right:  0 }
.C7D19o{ padding-left:   0 }
.R3a5i0{ padding-bottom: 0 }
/*
    ----------
*/
.GO52g1{ border-radius: 0    }
.g03C00{ border-radius: 25%  }
.K79e1C{ border-radius: 50%  }
.pZR189{ border-radius: 75%  }
.kZm977{ border-radius: 100% }
/*
    ----------
*/
.wzU404{ flex: 0 0 auto }
.uTM149{ flex: 1 1 auto }
.U977pl{ flex: 0 1 auto }
.z097Vp{ flex: 1 0 auto }
/*
    ----------
*/
.P1470a{ flex-grow: 0 }
.iw2U41{ flex-grow: 1 }
.I750Xd{ flex-grow: 2 }
.S276Td{ flex-grow: 3 }
/*
    ----------
*/
.X594yq{ flex-basis: 1 }
.U716Tv{ flex-basis: 2 }
.Rzr931{ flex-basis: 3 }
/*
    ----------
*/
.o9T8I8{ flex-wrap: wrap         }
.R0X85t{ flex-wrap: nowrap       }
.Fv9Z90{ flex-wrap: wrap-reverse }
/*
    ----------
*/
.vE6956{ order: 1 }
.XjX424{ order: 2 }
.W85it5{ order: 3 }
/*
    ----------
*/
.Q330w3{ align-content: stretch       }
.v69MS6{ align-content: center        }
.X19jp8{ align-content: space-evenly  }
.v74JJ5{ align-content: flex-start    }
.uH27J4{ align-content: flex-end      }
.T3l2s4{ align-content: space-between }
.D123Qj{ align-content: space-around  }
/*
    ----------
*/
.j1Mr03{ flex-shrink: 0 }
.n7OS97{ flex-shrink: 1 }
/*
    ----------
*/
.Ax30Q1{ box-shadow: none }
/*
    ----------
*/
.J55M9e{ content: "" }
/* 
    ----------
*/
.C4v153{ outline: 0    }
.sD881L{ outline: auto }
/* 
    ----------
*/
.pL4U70{ opacity: 0    }
.q693Lc{ opacity: 25%  }
.aT591i{ opacity: 50%  }
.S8k6H9{ opacity: 75%  }
.aV498I{ opacity: 100% }
/*
    ----------
*/
.cB943h{ -webkit-line-clamp: 2 }
.Bt6232{ -webkit-line-clamp: 3 }
.X2a9R4{ -webkit-line-clamp: 4 }
/*
    ----------
*/
.gDk539{ -webkit-box-orient: block-axis  }
.J4i292{ -webkit-box-orient: inline-axis }
.uQ1c23{ -webkit-box-orient: horizontal  }
.G7o96W{ -webkit-box-orient: vertical    }
/*
    ----------
*/
.c8R6W7{ list-style: decimal-leading-zero }
.WvX121{ list-style: lower-alpha          }
.Iy85Z8{ list-style: lower-greek          }
.X6y6H0{ list-style: lower-latin          }
.xT170D{ list-style: lower-roman          }
.J564hZ{ list-style: upper-alpha          }
.Qd352p{ list-style: upper-latin          }
.Np616D{ list-style: upper-roman          }
.W18b0l{ list-style: disc                 }
.bR099N{ list-style: georgian             }
.Jd2580{ list-style: inside               }
.R723p4{ list-style: outside              }
.dK9146{ list-style: square               }
.h57Y8Q{ list-style: circle               }
.kO539q{ list-style: armenian             }
.vY001R{ list-style: decimal              }
.Au5718{ list-style: numeric              }
.GnG350{ list-style: none                 }
/*
    ----------
*/
.C196Fv{ writing-mode: horizontal-tb }
.Z95a8x{ writing-mode: vertical-lr   }
.x0F757{ writing-mode: vertical-rl   }
/*
    ----------
*/
.Klc797{ transition: all .8s ease }
/*
    ----------
*/
.t0T2S2{ transform: none           }
.wD3v95{ transform: rotate(90deg)  }
.Zka424{ transform: rotate(180deg) }
.v1M46U{ transform: rotate(270deg) }
.j833Ma{ transform: rotate(360deg) }
/*
    ----------
*/
.jX0924{ object-fit: fill       }
.C0go67{ object-fit: contain    }
.Mg1457{ object-fit: cover      }
.uX61k8{ object-fit: scale-down }
.y60E9p{ object-fit: none       }
/* 
    ----------
*/
.qW182T{ line-height: 1.0 }
.tSz942{ line-height: 1.5 }
.YS535e{ line-height: 2.0 }
/*
    ----------
*/
.SFq502{ background-position: left top      }
.iJM161{ background-position: left center   }
.Gr070F{ background-position: left bottom   }
.Px402I{ background-position: right top     }
.t6K867{ background-position: right center  }
.mG848G{ background-position: right bottom  }
.J2l421{ background-position: center top    }
.F710rg{ background-position: center center }
.vW8q13{ background-position: center bottom }
/*
    ----------
*/
.Tq143x{ background-size: auto    }
.T659Fi{ background-size: cover   }
.B4A44p{ background-size: contain }
/*
    ----------
*/
.P6cE22{ background-repeat: repeat    }
.XcJ938{ background-repeat: repeat-x  }
.rYx754{ background-repeat: repeat-y  }
.N965Go{ background-repeat: no-repeat }
.rG790K{ background-repeat: space     }
.kV124B{ background-repeat: round     }
/*
    ----------
*/
.P779mc{ box-sizing: border-box }
/*
    ----------
*/
.H9g95G{ word-break: normal     }
.kO189r{ word-break: break-all  }
.wK1970{ word-break: keep-all   }
.kL64Z1{ word-break: break-word }
/*
    ----------
*/
.q629Gd{ filter: brightness(0.5)   }
.jSO180{ filter: contrast(160%)     }
.H61jT8{ filter: saturate(3)        }
.wM7o82{ filter: invert(100%)       }
.Q8b7e3{ filter: grayscale(50%)     }
.T354Vs{ filter: sepia(100%)        }
.U029ho{ filter: blur(3px)          }
.o98b8U{ filter: hue-rotate(142deg) }
/*
    ----------
*/
.C23pq8{ resize: both       }
.qS03n2{ resize: none       }
.Ss37t2{ resize: horizontal }
.n3R12l{ resize: vertical   }
/*
    ----------
*/
@mixin Size( $Width, $Height ){ 
    width  : $Width; 
    height : $Height 
}
@mixin Start( $Start, $Top ){ 
    left : $Start; 
    top  : $Top 
}
@mixin End( $End, $Top ){ 
    right : $End; 
    top   : $Top 
}
@mixin Top( $Start, $Bottom ){ 
    left   : $Start; 
    bottom : $Bottom 
}
@mixin Bottom( $End, $Bottom ){ 
    right  : $End; 
    bottom : $Bottom 
}
@mixin Margin( $Top, $End, $Bottom, $Start ){ 
    margin: $Top $End $Bottom $Start 
}
@mixin Padding( $Top, $End, $Bottom, $Start ){ 
    padding: $Top $End $Bottom $Start 
}
/* 
    ----------
*/
:root{
    --css-Dark        : #E6E1D6;
    --css-Grey        : #E3E3E3;
    --css-Gray        : #CBC8C2;
    --css-Black       : #4A4946;
    --css-Light       : #F0ECE4;
    --css-Green       : #EBF9EC;
    --css-Transparent : transparent;
}
/* 
    ----------
*/
:root{
    --css-Success  : #5AD363;
    --css-Discount : #646464;
    --css-Search   : #E5E5E5;
}
/* 
    ----------
*/
:root{
    --css-WhatsApp : #25D366
}
/* 
    ----------
*/
:root{
    --css-Single-Headline : 25px;
    --css-Single-Sub      : 22px;
}
/* 
    ----------
*/
:root{
    --css-Small    : 12px;
    --css-Smallest : 13.4px;
    --css-Medium   : 23px;
    --css-Big      : 24px;
    --css-Large    : 40px;
}
/* 
    ----------
*/
html{
    @extend .dE5574;
}
a{
    @extend .v6EU83;
    @extend .vF5k47;
}
a:hover{
    @extend .Q3s2S1;
}
/* 
    ----------
*/
body{
    @extend .dE5574;
    @extend .Wm8G06;
    @extend .rP408N;
}
/* 
    ----------
*/
.tp4h92{
    @include Size( 100%, auto );
    @extend .Klc797;
    .h827np{
        @include Size( 100%, auto );
        @extend .cH8711;
        z-index: 9997;
        .izl824{
            @include Size( 100%, 70px );
            @extend .Klc797;
            .a82t7y{
                @include Size( 100%, auto );
                @extend .Klc797;
                @extend .C4v153;
                @extend .cH8711;
                svg{
                    @include Size( 16px, 16px );
                    margin: -1px 0 0 0;
                }
            }
            .j56448{
                @include Size( 100%, auto );
                @extend .Klc797;
                @extend .C4v153;
                @extend .cH8711;
                svg{
                    @include Size( 17px, 17px );
                    margin: -1px 0 0 0;
                }
            }
            .a142pz{
                @include Size( 100%, 65px );
                @extend .Klc797;
                @extend .C4v153;
                @extend .cH8711;
                @extend .N4g4m6;
                svg{
                    @include Size( 17px, 17px );
                    margin: -1px 0 0 0;
                }
                &:hover .ty22m7{
                    @extend .V81zg1;
                }
            }
            .x3q2c6{
                @include Size( 100%, 13px );
                @include End( -12px, -18px );
                @extend .rP408N;
                @extend .vOx602;
                @extend .K0cW96;
                @extend .pEO064;
                padding: 1px 0 0 0;
                font-size: map-get( $Size, "10" ) - 3px;
                max-width: 13px
            }
            .ty22m7{
                @include Size( 100%, auto );
                @include End( 4px, 60px );
                @extend .rP408N;
                @extend .Klc797;
                @extend .hPP928;
                min-width: 180px;
                z-index: 999;
                .r28345{
                    @include Size( 100%, 40px );
                    @extend .Klc797;
                    @extend .Y0u334;
                    @extend .iI0402;
                    @extend .K0cW96;
                    @extend .gZ959C;
                    line-height: 40px;
                }
                .h333i8{
                    @include Size( 100%, auto );
                    @extend .GnG350;
                    li{
                        a{
                            @include Size( 100%, 36px );
                            @extend .iI0402;
                            @extend .Y0u334;
                            @extend .K0cW96;
                            @extend .pEO064;
                            @extend .a527eB;
                            @extend .vF5k47;
                            @extend .Klc797;
                            border-bottom: 1px solid var(--css-Grey);
                            line-height: 36px;
                            &:hover{
                                transform: translate(8px);
                            }
                        }
                        &:last-child a{
                            @extend .PvN976;
                        }
                    }
                }
                &::after{
                    @include End( 0, 0 );
                    @extend .J55M9e;
                    @extend .gBt584;
                    margin: -10px 0 0 0;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent transparent map-get( $Color, "Light" ) transparent;
                }
            }
        }
        .pq33z1{
            @include Size( 100%, auto );
            @extend .Klc797;
            .b247gl{
                @include Size( 100%, 70px );
                @extend .GnG350;
                li{
                    a{
                        @include Size( 100%, 70px );
                        @extend .pEO064;
                        @extend .xG0j09;
                        @extend .M99v3q;
                        @extend .K0cW96;
                        @extend .C4v153;
                        @extend .cB3332;
                        padding: 0 20px;
                    }
                    &:first-child a{
                        @extend .C7D19o;
                    }
                    &:last-child a{
                        @extend .dW8M00;
                    }
                }
            }
            .s68c45{
                @include Start( 0, 70px );
                @include Size( 100%, auto );
                @extend .GnG350;
                background: var(--css-Light);
                border-top: 1px solid var(--css-Gray);
                .b70r6q{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .r7g188{
                        @extend .xG0j09;
                        @extend .vOx602;
                        @extend .K0cW96;
                    }
                    .j2t3c3{
                        @include Size( 100%, auto );
                        @extend .GnG350;
                        li{
                            a{
                                @include Size( 100%, auto );
                                @extend .Klc797;
                                @extend .a527eB;
                                @extend .vF5k47;
                                @extend .Y0u334;
                                font-size: var(--css-Small);
                                margin: 0 0 8px;
                                line-height: 19px;
                                &:hover{
                                    @extend .vOx602;
                                }
                            }
                        }
                    }
                }
                .fv710l{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .dd382b{
                        @include Size( 100%, auto );
                        @extend .Klc797;
                        .q450lf{
                            @include Size( 100%, auto );
                            @extend .Klc797;
                            @extend .PlO170;
                            @extend .M99v3q;
                            @extend .K0cW96;
                            img{
                                @include Size( 100%, auto );
                                @extend .Klc797;
                            }
                        }
                        &::before{
                            @include Start( 0, 0 );
                            @include Size( 100%, 100% );
                            @extend .J55M9e;
                            @extend .S6pt64;
                            @extend .gBt584;
                            background-color: rgba( map-get( $Color, "Black" ), .3 );
                        }
                    }
                    .o308gh{
                        @include Size( 100%, auto );
                        @extend .Klc797;
                    }
                    &:hover .dd382b img{
                        @extend .Klc797;
                        transform: scale(1.2)
                    }
                }
                .col-md-3{
                    @include Size( 20%, auto );
                }
                .col-md-4{
                    @include Size( 25%, auto );
                }
            }
        }
        .u3505r{
            @include Size( 100%, auto );
            @extend .Klc797;
            .g551a7{
                @include Size( 100%, 65px );
                @extend .Klc797;
                @extend .C4v153;
                svg{
                    @include Size( 69px, auto );
                    @extend .Klc797;
                    path{
                        @extend .Klc797;
                        @extend .f8T6I8;
                    }
                }
            }
        }
    }
    .w42q6d{
        @include Size( 100%, 50vh );
        @extend .n2813w;
        background: var(--css-Light);
        z-index: 9999;
        .w21474{
            @include Size( 100%, auto );
            .ox83u7{
                @include Size( 100%, auto );
                max-width: 825px;
                .s37s70{
                    @include Size( 100%, 52px );
                    @extend .C4v153;
                    @extend .ob2A34;
                    @extend .xG0j09;
                    @extend .vOx602;
                    @extend .pEO064;
                    padding: 0 0 0 52px;
                    background: var(--css-Dark);
                    &::placeholder{
                        @extend .vOx602;
                        @extend .aV498I;
                    }
                }
                .m87bg8{
                    @include Size( 100%, 52px );
                    max-width: 52px;
                    svg{
                        @include Size( 100%, 10px );
                        margin: -3px 0 0 0;
                        max-width: 10px;
                    }
                }
                .c02j84{
                    @include Bottom( 0, 0 );
                    @include Size( 100%, 52px );
                    max-width: 52px;
                    svg{
                        @include Size( 100%, 13px );
                        margin: -3px 0 0 0;
                        max-width: 13px;
                    }
                }
            }
        }
    }
}
/* 
    ----------
*/
.n91c77{
    @include Size( 100%, auto );
    @extend .Klc797;
    .o3664v{
        @include Size( 100%, auto );
        @extend .a527eB;
        margin: -80px 0 0 0;
        .a3250o{
            @include Size( 100%, auto );
            @extend .cH8711;
            .j5q81i{
                @include Size( 100%, auto );
                @extend .cH8711;
                &::before{
                    @include Start( 0, 0 );
                    @include Size( 100%, 100% );
                    @extend .J55M9e;
                    @extend .S6pt64;
                    @extend .gBt584;
                    background: rgba( map-get( $Color, "Black" ), .3 );
                    z-index: 99;
                }
            }
        }
    }
    .q819fu{
        @include Size( 100%, 100% );
        @extend .Klc797;
        .q285z4{
            @include Start( 25%, 0 );
            @include Size( 100%, 100% );
            border-right: 1px solid rgba( map-get( $Color, "White" ), .2 );
            max-width: 1px;
        }
        .c3x07a{
            @include Start( 50%, 0 );
            @include Size( 100%, 100% );
            border-right: 1px solid rgba( map-get( $Color, "White" ), .2 );
            max-width: 1px;
        }
        .c3n35z{
            @include Start( 75%, 0 );
            @include Size( 100%, 100% );
            border-right: 1px solid rgba( map-get( $Color, "White" ), .2 );
            max-width: 1px;
        }
    }
    .aq566j{
        @include Size( 100%, auto );
        @extend .Klc797;
        @extend .vF5k47;
        .swiper{
            @include Size( 100%, auto );
            @extend .Klc797;
            z-index: 100;
        }
        .r5m3s3{
            @include Size( 100%, auto );
            @extend .Klc797;
            @extend .vF5k47;
            z-index: 100;
            .e9584q{
                @extend .M99v3q;
                @extend .K0cW96;
                font-size: var(--css-Large);
                max-width: 460px;
                line-height: 56px;
            }
            .x6x21h{
                @extend .PlO170;
                @extend .M99v3q;
                @extend .K0cW96;
                @extend .S8k6H9;
                max-width: 460px;
                line-height: 30px;
            }
            .a73e58{
                @include Size( 100%, 53px );
                @extend .rP408N;
                @extend .PlO170;
                @extend .vOx602;
                @extend .Klc797;
                @extend .C4v153;
                @extend .vF5k47;
                border: 2px solid map-get( $Color, "White" );
                border-radius: 53px;
                max-width: 220px;
                &:hover{
                    @extend .M99v3q;
                    background: var(--css-Transparent);
                }
            }
        }
        .v258gv{
            @include Size( 100%, 100vh );
            @extend .Klc797;
            .y02vy3{
                @include Size( 100%, auto );
                @extend .Klc797;
                .swiper-pagination-bullet{
                    @include Size( 28px, 28px );
                    @extend .cH8711;
                    @extend .rP408N;
                    @extend .S0u16d;
                    @extend .vR05U6;
                    @extend .d721Vx;
                    @extend .aV498I;
                    background: var(--css-04j8x5);
                    margin: 0 0 10px 0;
                    z-index: 99;
                    &:last-child{
                        margin: 0;
                    }
                    &::before{
                        @include Size( 5px, 5px );
                        @extend .J55M9e;
                        @extend .rP408N;
                        @extend .kZm977;
                        @extend .wDC543;
                    }
                }
                .swiper-pagination-bullet-active{
                    border: 2px solid map-get( $Color, "White" );
                }
            }
            .c7ar92{
                @include Size( 100%, 100% );
                @extend .Klc797;
                .t003r7{
                    @include Size( 100%, 28px );
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .a527eB;
                    @extend .ob2A34;
                    @extend .rP408N;
                    margin: 0 0 17px 0;
                    max-width: 28px;
                    z-index: 99;
                    &:hover{
                        @extend .u3I753;
                        &::before{
                            content: url( "./Media/Icon/006.svg" );
                        }
                    }
                    &::before{
                        @include Size( 100%, 100% );
                        @extend .S0u16d;
                        @extend .vR05U6;
                        @extend .d721Vx;
                        @extend .a527eB;
                        content: url( "./Media/Icon/005.svg" );
                        margin: 4px 0 0 1.58px;
                    }
                }
                .w181kh{
                    @include Size( 100%, 28px );
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .a527eB;
                    @extend .ob2A34;
                    @extend .ob2A34;
                    @extend .rP408N;
                    margin: 17px 0 0 0;
                    max-width: 28px;
                    z-index: 99;
                    &:hover{
                        @extend .u3I753;
                        &::before{
                            content: url( "./Media/Icon/007.svg" );
                        }
                    }
                    &::before{
                        @include Size( 100%, 100% );
                        @extend .S0u16d;
                        @extend .vR05U6;
                        @extend .d721Vx;
                        @extend .a527eB;
                        content: url( "./Media/Icon/004.svg" );
                        margin: 7px 0 0 1.58px;
                    }
                }
            }
        }
    }
    .k6037f{
        @include Size( 100%, auto );
        @extend .Klc797;
        .q4570i{
            @include Size( 100%, 350px );
            background: var(--css-Light);
            .b9s940{
                @include Size( 100%, 100% );
                @extend .Klc797;
                .gc3a48{
                    @extend .wI860z;
                    @extend .Y0u334;
                    @extend .K0cW96;
                    line-height: 30px
                }
                .nbc058{
                    @extend .xG0j09;
                    @extend .Y0u334;
                    @extend .nS533w;
                    line-height: 23px
                }
                .i7tk72{
                    @extend .xG0j09;
                    @extend .Y0u334;
                    @extend .nS533w;
                    @extend .Q3s2S1;
                    @extend .cB3332;
                    line-height: 23px;
                    &:hover{
                        @extend .vF5k47;
                    }
                }
            }
        }
        .qn874y{
            @include Size( 100%, 350px );
            background: var(--css-Dark);
            .k8k4c9{
                @include Size( 100%, 100% );
                @extend .Klc797;
                .nwa810{
                    @extend .wI860z;
                    @extend .Y0u334;
                    @extend .K0cW96;
                    line-height: 30px
                }
                .m6h3l4{
                    @extend .xG0j09;
                    @extend .Y0u334;
                    @extend .nS533w;
                    line-height: 23px
                }
                .b1d6x9{
                    @extend .xG0j09;
                    @extend .Y0u334;
                    @extend .nS533w;
                    @extend .Q3s2S1;
                    @extend .cB3332;
                    line-height: 23px;
                    &:hover{
                        @extend .vF5k47;
                    }
                }
            }
        }
    }
    .snf660{
        @include Size( 100%, auto );
        @extend .Klc797;
        .w31jj7{
            @include Size( 100%, auto );
            @extend .rP408N;
            border: 1px solid var(--css-Grey);
            .e699av{
                @include Size( 100%, 172px );
                @extend .gZ959C;
            }
            .d415fs{
                @include Size( 100%, auto );
                @extend .Klc797;
                .b667t7{
                    @include Size( 100%, auto );
                    @extend .iI0402;
                    @extend .K0cW96;
                    color: var(--css-Discount);
                    line-height: 22px
                }
                .c35m02{
                    @include Size( 100%, auto );
                    @extend .vOx602;
                    @extend .K0cW96;
                    font-size: var(--css-Smallest);
                    line-height: 21px
                }
                .on389s{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    line-height: 32px;
                    svg{
                        @include Size( 48px, auto );
                        @extend .Klc797;
                    }
                }
                .qq8011{
                    @include Size( 100%, auto );
                    @extend .PlO170;
                    @extend .K0cW96;
                    @extend .h6T64Y;
                    color: var(--css-Discount);
                    line-height: 29px
                }
                .iq184y{
                    @include Size( 100%, auto );
                    @extend .PlO170;
                    @extend .Y0u334;
                    @extend .K0cW96;
                    line-height: 29px
                }
                .qq906n{
                    @include Size( 100%, auto );
                    @extend .PlO170;
                    @extend .vOx602;
                    @extend .K0cW96;
                    line-height: 26px
                }
            }
            &:hover .d2a4d7{
                @extend .aV498I;
            }
        }
        .v488wk{
            @include Size( 100%, auto );
            @extend .Klc797;
            video{
                @include Size( 100%, 100% );
                @extend .Klc797;
            }
        }
    }
    .qo482c{
        @include Size( 100%, auto );
        @extend .Klc797;
        .i2b395{
            @include Size( 100%, auto );
            @extend .Klc797;
            .v6591c{
                @include Size( 100%, 424px );
                @extend .gZ959C;
            }
            .e2l151{
                @include Size( 100%, 424px );
                @extend .Klc797;
                .x17320{
                    @extend .vOx602;
                    @extend .K0cW96;
                    @extend .i260Up;
                    line-height: 31px;
                }
                .z69b30{
                    @extend .Y0u334;
                    @extend .K0cW96;
                    @extend .wI860z;
                    line-height: 30px;
                }
                .o77kb6{
                    @include Size( 100%, 44px );
                    @extend .Klc797;
                    @extend .rP408N;
                    @extend .xG0j09;
                    @extend .Y0u334;
                    border: 1px solid map-get( $Color, "Grey" );
                    max-width: 170px;
                    border-radius: 44px;
                    line-height: 44px;
                }
            }
            &:hover .e2l151 .o77kb6{
                @extend .u0Bc59;
                @extend .M99v3q;
                border: 1px solid map-get( $Color, "Default" );
            }
        }
    }
    .bt779q{
        @include Size( 100%, auto );
        background: var(--css-Light);
        .v04h1j{
            @include Size( 100%, auto );
            @extend .Klc797;
            background: var(--css-Dark);
            border-radius: 8px;
            .dz1298{
                @include Size( 100%, auto );
                @extend .Klc797;
                .j47l6a{
                    @extend .Wm8G06;
                    @extend .Y0u334;
                    @extend .K0cW96;
                    line-height: 28px;
                }
                .qde061{
                    @extend .Y0u334;
                    @extend .nS533w;
                    font-size: var(--css-Smallest);
                    line-height: 23.5px;
                }
                .s5re57{
                    @include Size( 100%, 34px );
                    @extend .Klc797;
                    @extend .kZm977;
                    border: 1px solid map-get( $Color, "Grey" );
                    max-width: 34px;
                    svg{
                        @include Size( 12px, 12px );
                        @extend .Klc797;
                        margin: 1px 0 0 1px;
                        path{
                            @extend .Klc797;
                            @extend .Vr4196;
                        }
                    }
                }
            }
            &:hover .s5re57{
                @extend .u0Bc59;
                border: 1px solid map-get( $Color, "Default" );
                svg path{
                    fill: map-get( $Color, "White" );
                }
            }
        }
    }
    .c16yf4{
        @include Size( 100%, auto );
        @extend .Klc797;
        .v6568a{
            @include Size( 100%, auto );
            @extend .Klc797;
            .q8p2f2{
                @include Size( 100%, 306px );
                @extend .gZ959C;
            }
            .g4co75{
                @include Size( 100%, auto );
                @extend .Klc797;
                .r4v633{
                    @include Size( 100%, auto );
                    @extend .iI0402;
                    @extend .Y0u334;
                    @extend .K0cW96;
                    line-height: 22px
                }
                .jm47o9{
                    @include Size( 100%, auto );
                    @extend .vOx602;
                    @extend .K0cW96;
                    font-size: var(--css-Smallest);
                    line-height: 21px
                }
            }
        }
    }
    .y8i3b3{
        @include Size( 100%, auto );
        @extend .Klc797;
        .u6y50s{
            @extend .i260Up;
            @extend .D933nr;
            @extend .Y0u334;
        }
    }
}
/* 
    Group
*/
.d2a4d7{
    @include Size( 100%, 100% );
    @extend .pL4U70;
    .t474t4{
        @include Size( 100%, 30px );
        @extend .gZ959C;
        max-width: 30px;
        .it9s61{
            @include Size( 100%, auto );
            @extend .Klc797;
            svg{
                @include Size( 11.5px, 11.5px );
                margin: 1px 0 0 1px;
            }
        }
        .e5830w{
            @include Size( 100%, auto );
            @extend .Klc797;
            svg{
                @include Size( 13px, 13px );
                margin: 1px 0 0 0;
                path{
                    @extend .Klc797;
                    @extend .Vr4196;
                }
            }
        }
        .d11g5z{
            @include Size( 100%, auto );
            @extend .Klc797;
            svg{
                @include Size( 10px, 10px );
                @extend .Klc797;
                path{
                    @extend .Klc797;
                    @extend .Vr4196;
                }
            }
        }
        &:hover{
            @extend .Klc797;
            @extend .u0Bc59;
            .it9s61 svg path{
                stroke: map-get( $Color, "White" );
            }
            .e5830w svg path{
                fill: map-get( $Color, "White" );
            }
            .d11g5z svg path{
                fill: map-get( $Color, "White" );
            }
        }
        &:hover .b93t68{
            @extend .wDC543;
        }
        &:hover .dm7117{
            @extend .wDC543;
        }
        &:hover .nme879{
            @extend .wDC543;
        }
    }
    .b93t68{
        @include Start( 0, 32px );
        @include Size( 100%, 27px );
        @extend .i3eE58;
        @extend .eU7H63;
        @extend .M99v3q;
        @extend .J284Xb;
        @extend .hPP928;
        min-width: 110px;
        line-height: 27px;
        &::before{
            @include Top( 14%, 100% );
            @extend .J55M9e;
            @extend .gBt584;
            margin: 0 0 0 -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent map-get( $Color, "Grey" ) transparent;
        }
    }
    .dm7117{
        @include Start( 0, 32px );
        @include Size( 100%, 27px );
        @extend .i3eE58;
        @extend .eU7H63;
        @extend .M99v3q;
        @extend .J284Xb;
        @extend .hPP928;
        min-width: 90px;
        line-height: 27px;
        &::before{
            @include Top( 16%, 100% );
            @extend .J55M9e;
            @extend .gBt584;
            margin: 0 0 0 -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent map-get( $Color, "Grey" ) transparent;
        }
    }
    .nme879{
        @include Start( 0, 32px );
        @include Size( 100%, 27px );
        @extend .i3eE58;
        @extend .eU7H63;
        @extend .M99v3q;
        @extend .J284Xb;
        @extend .hPP928;
        min-width: 100px;
        line-height: 27px;
        &::before{
            @include Top( 16%, 100% );
            @extend .J55M9e;
            @extend .gBt584;
            margin: 0 0 0 -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent map-get( $Color, "Grey" ) transparent;
        }
    }
}
/* 
    Quick
*/
.ok739d{
    @include End( 0, 400px );
    @include Size( 100%, 150px );
    @extend .Klc797;
    @extend .C4v153;
    @extend .u0Bc59;
    @extend .ob2A34;
    @extend .Z95a8x;
    @extend .xG0j09;
    @extend .M99v3q;
    @extend .K0cW96;
    max-width: 40px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
/* 
    Information
*/
.j684p1{
    @include Size( 100%, auto );
    @extend .Klc797;
    .fs7d60{
        @include Size( 100%, 40px );
        @extend .Klc797;
        @extend .C4v153;
        @extend .ob2A34;
        background: var(--css-Light);
        max-width: 40px;
        min-width: 40px;
        z-index: 999;
        svg{
            @include Size( 100%, 17px );
            @extend .Klc797;
            max-width: 17px;
        }
        .pi55p2{
            @include Start( 50px, 0 );
            @include Size( 100%, 40px );
            @extend .iI0402;
            @extend .Y0u334;
            @extend .K0cW96;
            @extend .hPP928;
            background: var(--css-Light);
            max-width: 125px;
            min-width: 125px;
            line-height: 40px;
            &::before{
                @include Start( -9px, 50% );
                @extend .J55M9e;
                @extend .gBt584;
                border-width: 5px;
                border-style: solid;
                border-color: transparent var(--css-Light) transparent transparent;
                margin: -5px 0 0 0;
            }
        }
        &:hover{
            @extend .u0Bc59;
            svg path{
                @extend .Klc797;
                @extend .f8T6I8;
            }
        }
        &:hover .pi55p2{
            @extend .V81zg1;
        }
    }
    .w269ju{
        @include Size( 100%, 40px );
        @extend .Klc797;
        @extend .C4v153;
        @extend .ob2A34;
        background: var(--css-Light);
        max-width: 40px;
        min-width: 40px;
        border-radius: 4px;
        z-index: 999;
        .s9871j{
            @include Start( 50px, 0 );
            @include Size( 100%, 40px );
            @extend .iI0402;
            @extend .Y0u334;
            @extend .K0cW96;
            @extend .hPP928;
            background: var(--css-Light);
            max-width: 150px;
            min-width: 150px;
            line-height: 40px;
            &::before{
                @include Start( -9px, 50% );
                @extend .J55M9e;
                @extend .gBt584;
                border-width: 5px;
                border-style: solid;
                border-color: transparent var(--css-Light) transparent transparent;
                margin: -5px 0 0 0;
            }
        }
        svg{
            @include Size( 100%, 19.5px );
            @extend .Klc797;
            max-width: 19.5px;
        }
        &:hover{
            @extend .u0Bc59;
            svg path{
                @extend .Klc797;
                stroke: map-get( $Color, "White" );
            }
        }
        &:hover .s9871j{
            @extend .V81zg1;
        }
    }
    .e9z13o{
        @include Size( 100%, 40px );
        @extend .Klc797;
        @extend .C4v153;
        @extend .ob2A34;
        background: var(--css-Light);
        max-width: 40px;
        min-width: 40px;
        z-index: 999;
        svg{
            @include Size( 100%, 16px );
            @extend .Klc797;
            max-width: 16px;
        }
        .ct398d{
            @include Start( 50px, 0 );
            @include Size( 100%, 40px );
            @extend .iI0402;
            @extend .Y0u334;
            @extend .K0cW96;
            @extend .hPP928;
            background: var(--css-Light);
            max-width: 125px;
            min-width: 125px;
            line-height: 40px;
            &::before{
                @include Start( -9px, 50% );
                @extend .J55M9e;
                @extend .gBt584;
                border-width: 5px;
                border-style: solid;
                border-color: transparent var(--css-Light) transparent transparent;
                margin: -5px 0 0 0;
            }
        }
        &:hover{
            @extend .u0Bc59;
            svg path{
                @extend .f8T6I8;
            }
        }
        &:hover .ct398d{
            @extend .V81zg1;
        }
    }
}
.v500i2{
    @include Top( 0, 153px );
    @extend .Klc797;
}
/* 
    Background
*/
.a10892{
    @include Start( 0, 0 );
    @include Size( 100%, 100% );
    @extend .Klc797;
    z-index: 999;
    .ma2337{
        @include Top( 0, 48px );
        @extend .Klc797;
        .ip434h{
            @include Size( 100%, 75px );
            @extend .Klc797;
            background: var(--css-Dark);
            border-left: 6px solid map-get( $Color, "Default" );
            max-width: 350px;
            .f92332{
                @include Size( 100%, 75px );
                @extend .Klc797;
                .d159rv{
                    @include Size( 100%, auto );
                    @extend .xG0j09;
                    @extend .Y0u334;
                    @extend .K0cW96;
                }
                .c781la{
                    @include Size( 100%, auto );
                    @extend .GnG350;
                    li{
                        a{
                            @include Size( 100%, 32px );
                            @extend .Klc797;
                            @extend .C4v153;
                            border: 1px solid map-get( $Color, "Grey" );
                            min-width: 32px;
                            max-width: 32px;
                        }
                    }
                }
                .sy3757{
                    @include Size( 100%, 32px );
                    @extend .i3eE58;
                    svg{
                        @include Size( 14px, 14px );
                        @extend .f8T6I8;
                    }
                }
                .ex173b{
                    @include Size( 100%, 32px );
                    @extend .i3eE58;
                    svg{
                        @include Size( 12px, 12px );
                        @extend .f8T6I8;
                    }
                }
                .o7f8w9{
                    @include Size( 100%, 32px );
                    @extend .i3eE58;
                    svg{
                        @include Size( 17px, 17px );
                        @extend .f8T6I8;
                    }
                }
            }
            .jb385k{
                @include Size( 100%, 24px );
                @include End( -12px, -12px );
                @extend .Klc797;
                @extend .C4v153;
                background: map-get( $Color, "Default" );
                border: 1px solid map-get( $Color, "Default" );
                max-width: 24px;
                svg{
                    @include Size( 7px, 7px );
                    @extend .Klc797;
                }
                &:hover svg{
                    @extend .j833Ma;
                }
            }
        }
    }
}
/* 
    Cookie Policy
*/
.c6j6q7{
    @include Start( 0, 0 );
    @include Size( 100%, 100vh );
    @extend .Klc797;
    background: rgba( map-get( $Color, "Black" ), .5 );
    z-index: 9999;
    .b1046d{
        @include Bottom( 0, 24px );
        @include Size( 100%, 100vh );
        @extend .Klc797;
        .r26k5m{
            @include Size( 100%, auto );
            background: var(--css-Light);
            max-width: 435px;
            .s8529h{
                @include Size( 100%, 44px );
                @extend .Klc797;
                @extend .iI0402;
                @extend .Y0u334;
                @extend .K0cW96;
                background: var(--css-Dark);
                line-height: 44px;
            }
            .c61044{
                @include Size( 100%, auto );
                @extend .Klc797;
                .fi6d43{
                    @extend .S7gw68;
                    @extend .Y0u334;
                    line-height: 19px;
    
                }
            }
            .z1714g{
                @include Size( 100%, auto );
                @extend .Klc797;
                .d203e2{
                    @include Size( 100%, 38px );
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .u0Bc59;
                    @extend .S7gw68;
                    @extend .M99v3q;
                    @extend .K0cW96;
                    border: 1px solid map-get( $Color, "Default" );
                    max-width: 122px;
                    line-height: 38px;
                    &:hover{
                        @extend .S8k6H9;
                    }
                }
                .dz5v37{
                    @include Size( 100%, 38px );
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .S7gw68;
                    @extend .v6EU83;
                    @extend .K0cW96;
                    @extend .rP408N;
                    border: 1px solid map-get( $Color, "Default" );
                    max-width: 122px;
                    line-height: 38px;
                    &:hover{
                        @extend .u0Bc59;
                        @extend .M99v3q;
                    }
                }
                .r7yt40{
                    @include Size( 100%, 38px );
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .S7gw68;
                    @extend .v6EU83;
                    @extend .K0cW96;
                    @extend .rP408N;
                    border: 1px solid map-get( $Color, "Default" );
                    max-width: 122px;
                    line-height: 38px;
                    &:hover{
                        @extend .u0Bc59;
                        @extend .M99v3q;
                    }
                }
            }
        }
    }
    .b095qv{
        @include Start( 0, 0 );
        @include Size( 100%, 100vh );
        @extend .Klc797;
        .c389ng{
            @include Size( 100%, auto );
            background: var(--css-Light);
            max-width: 585px;
            .h345zp{
                @include Size( 100%, 44px );
                @extend .Klc797;
                @extend .iI0402;
                @extend .Y0u334;
                @extend .K0cW96;
                background: var(--css-Dark);
                line-height: 44px;
                &::after{
                    @extend .yJ452A;
                    content: "Sürüm 2.0"
                }
            }
            .u30k1l{
                @include Size( 100%, auto );
                @extend .Klc797;
                .a246p3{
                    @extend .S7gw68;
                    @extend .Y0u334;
                    @extend .K0cW96;
                    line-height: 19px;
                }
                .q73uu8{
                    @extend .S7gw68;
                    @extend .Y0u334;
                    line-height: 19px;
                }
                .v24m05{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .h7b847{
                        @include Size( 100%, 38px );
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .u0Bc59;
                        @extend .S7gw68;
                        @extend .M99v3q;
                        @extend .K0cW96;
                        border: 1px solid map-get( $Color, "Default" );
                        max-width: 158px;
                        line-height: 38px;
                        &:hover{
                            @extend .S8k6H9;
                        }
                    }
                    .f09035{
                        @include Size( 100%, 38px );
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .S7gw68;
                        @extend .v6EU83;
                        @extend .K0cW96;
                        @extend .rP408N;
                        border: 1px solid map-get( $Color, "Default" );
                        max-width: 165px;
                        line-height: 38px;
                        &:hover{
                            @extend .u0Bc59;
                            @extend .M99v3q;
                        }
                    }
                    .a8f929{
                        @include Size( 100%, 38px );
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .S7gw68;
                        @extend .Y0u334;
                        @extend .K0cW96;
                        background: var(--css-Dark);
                        border: 1px solid var(--css-Dark);
                        max-width: 130px;
                        line-height: 38px;
                        &:hover{
                            @extend .rP408N;
                        }
                    }
                }
                .b54b9b{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .q6o401{
                        @include Size( 100%, auto );
                        @extend .Klc797;
                        border: 1px solid var(--css-Gray);
                        border-bottom: 0;
                        .f9w0x4{
                            @include Size( 100%, 40px );
                            @extend .Klc797;
                            @extend .ob2A34;
                            @extend .z9X51G;
                            border-right: 1px solid var(--css-Gray);
                            max-width: 40px;
                            svg{
                                @include Size( 15px, 15px );
                                @extend .Klc797;
                                path{
                                    @extend .Vr4196;
                                }
                            }
                        }
                        .ak3390{
                            @include Size( 100%, 40px );
                            @extend .Klc797;
                            @extend .ob2A34;
                            @extend .z9X51G;
                            border-right: 1px solid var(--css-Gray);
                            max-width: 40px;
                            svg{
                                @include Size( 15px, 15px );
                                @extend .Klc797;
                                path{
                                    @extend .Vr4196;
                                }
                            }
                        }
                        .x4w13i{
                            @include Start( 60px, 0 );
                            @include Size( 100%, 40px );
                            @extend .iI0402;
                            @extend .Klc797;
                            @extend .Y0u334;
                            @extend .K0cW96;
                            @extend .N4g4m6;
                            max-width: calc( 100% - 130px );
                        }
                        .yf230d{
                            @include Start( 60px, 0 );
                            @include Size( 100%, 40px );
                            @extend .iI0402;
                            @extend .Klc797;
                            @extend .Y0u334;
                            @extend .K0cW96;
                            @extend .N4g4m6;
                            max-width: calc( 100% - 80px );
                            &::after{
                                @extend .yJ452A;
                                content: "Her Zaman Etkin";
                            }
                        }
                    }
                    .i1455t{
                        @include Size( 100%, 40px );
                        @extend .Klc797;
                        border: 1px solid var(--css-Gray);
                    }
                    .h81e2y{
                        @include Size( 100%, 40px );
                        @extend .Klc797;
                        max-width: 90px;
                        .qa141i{
                            @include Size( 100%, 23px );
                            @extend .N4g4m6;
                            background: var(--css-Dark);
                            max-width: 46px;
                            border-radius: 9999px;
                            .y15732{
                                @include Size( 100%, 17px );
                                @extend .rP408N;
                                @extend .z9X51G;
                                max-width: 17px;
                                border-radius: 9999px;
                            }
                        }
                        .f3933g{
                            background: map-get( $Color, "Default" )!important;
                        }
                    }
                    .q9r500{
                        @include Size( 100%, auto );
                        @extend .Klc797;
                        @extend .z9X51G;
                        background: var(--css-Dark);
                        border-top: 1px solid var(--css-Gray);
                        .s36ba6{
                            @extend .S7gw68;
                            @extend .Y0u334;
                            @extend .z9X51G;
                            line-height: 19px;
                        }
                    }
                    .q6o401:last-child{
                        border-bottom: 1px solid var(--css-Gray);
                    }
                }
            }
        }
    }
}
/* 
    Page
*/
.j70q04{
    @include Size( 100%, auto );
    @extend .Klc797;
}
.o8005b{
    @include Size( 100%, auto );
    background: var(--css-Light);
    .os365t{
        @include Size( 100%, auto );
        @extend .Klc797;
        .a51q9f{
            @include Size( 100%, auto );
            @extend .Klc797;
            max-width: 860px
        }
        .m6293u{
            @include Size( 100%, auto );
            @extend .vOx602;
            @extend .K0cW96;
            font-size: var(--css-Single-Headline);
            line-height: 32px
        }
        .pf114d{
            @include Size( 100%, auto );
            @extend .Wm8G06;
            @extend .Y0u334;
            line-height: 25px;
        }
    }
}
.e171gb{
    @include Size( 100%, auto );
    @extend .Klc797;
    .mk7854{
        @include Size( 100%, auto );
        @extend .Klc797;
        .p0z52t{
            @include Size( 100%, auto );
            @extend .Klc797;
            .le855o{
                @include Size( 100%, auto );
                @extend .Klc797;
                img{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                }
            }
        }
    }
}
.f839d8{
    @include Size( 100%, auto );
    @extend .Klc797;
    background: var(--css-Light);
    .vm283g{
        @include Size( 100%, 80px );
        @extend .Klc797;
        background: var(--css-Light);
        border-bottom: 2px solid var(--css-Gray);
        .z2t071{
            @include Size( 100%, 80px );
            @extend .GnG350;
            li{
                a{
                    @extend .wI860z;
                    @extend .vOx602;
                    @extend .K0cW96;
                    @extend .pEO064;
                    @extend .vF5k47;
                    @extend .Klc797;
                    border-bottom: 2px solid transparent;
                    padding: 0 30px;
                    line-height: 80px;
                    &:hover{
                        @extend .vF5k47;
                        border-bottom: 2px solid map-get( $Color, "Default" );
                    }
                }
                .v566za{
                    border-bottom: 2px solid map-get( $Color, "Default" );
                }
            }
        }
    }
}
.s0k1u6{
    @include Size( 100%, auto );
    @extend .Klc797;
    .z55859{
        @include Size( 100%, auto );
        @extend .Klc797;
        img{
            @include Size( 100%, auto );
            @extend .Klc797;
        }
    }
    .r1618j{
        @include Bottom( 48px, -75px );
        @include Size( 100%, auto );
        @extend .rP408N;
        max-width: 820px;
        .km1708{
            @extend .i260Up;
            @extend .vOx602;
            @extend .K0cW96;
            line-height: 28px;
        }
        .h84oc8{
            @extend .wI860z;
            @extend .Y0u334;
            line-height: 24px;
        }
    }
}
.d97710{
    @include Size( 100%, auto );
    background: var(--css-Light);
    .z170es{
        @include Size( 100%, auto );
        @extend .Klc797;
        .pv4n82{
            @include Size( 100%, auto );
            @extend .Klc797;
            img{
                @include Size( 100%, auto );
                @extend .Klc797;
            }
        }
        .zr3056{
            @include Size( 100%, 100% );
            @extend .Klc797;
            .ie3620{
                @extend .vOx602;
                @extend .K0cW96;
                font-size: var(--css-Single-Sub);
                line-height: 31px;
            }
            .qa003m{
                @extend .wI860z;
                @extend .Y0u334;
                line-height: 24px;
            }
        }
    }
}
.ws229r{
    @include Size( 100%, auto );
    @extend .Klc797;
    background: var(--css-Light);
    .aq43w1{
        @include Size( 100%, 80px );
        @extend .Klc797;
        .a87y39{
            @include Size( 100%, auto );
            @extend .vOx602;
            @extend .K0cW96;
            font-size: var(--css-Single-Headline);
        }
    }
    .x153h1{
        @include Size( 100%, auto );
        @extend .Klc797;
        .zh621n{
            @include Size( 100%, auto );
            @extend .Klc797;
            img{
                @include Size( 100%, auto );
                @extend .Klc797;
            }
        }
        .vi6467{
            @extend .PlO170;
            @extend .vOx602;
            @extend .K0cW96;
            svg{
                @include Size( 8px, auto );
                margin: -1px 0 0 5px;
            }
        }
    }
    .equ096{
        @include Size( 100%, auto );
        @extend .Klc797;
        .a264s6{
            @include Size( 100%, auto );
            background: var(--css-Dark);
            max-width: 860px;
            .v928ih{
                @include Size( 100%, auto );
                @extend .Klc797;
                @extend .C4v153;
                @extend .PlO170;
                @extend .vOx602;
                @extend .K0cW96;
                .c3x53z{
                    @include Size( 100%, 48px );
                    @extend .Klc797;
                    border-bottom: 1px solid var(--css-Gray);
                    svg{
                        @include Size( 100%, 15px );
                        @extend .Klc797;
                        max-width: 15px;
                    }
                }
                .sf863s{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    p{
                        @extend .xG0j09;
                        @extend .vOx602;
                        @extend .nS533w;
                        line-height: 22px;
                    }
                }
            }
        }
    }
    .n28374{
        @include Size( 100%, auto );
        @extend .Klc797;
    }
    .col-md-2{
        @include Size( 20%, auto );
    }
}
/* 
    Breadcrumb
*/
.g9l5m9{
    @include Size( 100%, 48px );
    background: var(--css-Dark);
    .je635q{
        @include Size( 100%, 48px );
        @extend .GnG350;
        li{
            a{
                @extend .iI0402;
                @extend .vOx602;
                @extend .K0cW96;
                line-height: 48px
            }
            i{
                @extend .iI0402;
                @extend .vOx602;
                @extend .K0cW96;
                line-height: 48px;
                svg{
                    @include Size( 9.5px, 9.5px );
                    margin: 1px 0 0 0;
                    path{
                        @extend .sRU313;
                    }
                }
            }
        }
    }
}
/* 
    Footer
*/
.eg1579{
    @include Size( 100%, auto );
    @extend .Klc797;
    .vp581g{
        @include Size( 100%, auto );
        @extend .i3eE58;
        .i248mz{
            @include Size( 100%, auto );
            @extend .Klc797;
            .v5g27d{
                @include Size( 100%, auto );
                @extend .Klc797;
                .d607c1{
                    @include Size( 100%, auto );
                    @extend .M99v3q;
                    @extend .xG0j09;
                    @extend .K0cW96;
                }
                .f6932i{
                    @include Size( 100%, auto );
                    @extend .GnG350;
                    li{
                        a{
                            @include Size( 100%, auto );
                            @extend .iI0402;
                            @extend .Klc797;
                            color: var(--css-Gray);
                            margin: 0 0 9px;
                            line-height: 19px;
                            &:hover{
                                @extend .vF5k47;
                                @extend .M99v3q;
                            }
                        }
                    }
                }
            }
        }
        .qi0g80{
            @include Size( 100%, 100% );
            @extend .Klc797;
            .i0s907{
                @include Size( 100%, calc( 100% - 81px ) );
                background: var(--css-Black);
                max-width: .5px;
            }
        }
        .uw27j9{
            @include Size( 100%, auto );
            @extend .Klc797;
            .m7373k{
                @include Size( 100%, auto );
                @extend .Klc797;
                .h45j3c{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .k05ss5{
                        @include Size( 100%, auto );
                        @extend .xG0j09;
                        @extend .M99v3q;
                        @extend .K0cW96;
                    }
                    .b61409{
                        @include Size( 100%, auto );
                        @extend .Klc797;
                        @extend .a527eB;
                        @extend .vF5k47;
                        @extend .iI0402;
                        color: var(--css-Gray);
                        line-height: 23px
                    }
                }
            }
            .m0947t{
                @include Size( 100%, auto );
                @extend .Klc797;
                .d911nu{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .c83375{
                        @extend .K0cW96;
                        @extend .cB3332;
                        @extend .Q3s2S1;
                        @extend .iI0402;
                        color: var(--css-Gray);
                    }
                }
            }
            .t405qk{
                @include Size( 100%, auto );
                @extend .Klc797;
                border-top: 1px solid var(--css-Black);
                .u2p43p{
                    @include Size( 100%, auto );
                    @extend .iI0402;
                    color: var(--css-Gray);
                }
            }
        }
    }
}
/* 
    Header
*/
.s186w9{
    background: var(--css-Light);
    border-bottom: 1px solid var(--css-Gray);
}
/* 
    Backdrop
*/
.w3s37q{
    @include Start( 0, 0 );
    @include Size( 100%, 100vh );
    background: rgba( map-get( $Color, "Black" ), .3 );
    z-index: 9998;
}
/* 
    Menu
*/
.gb366i{
    background: var(--css-Light);
    .b247gl li a{
        color: map-get( $Color, "Black" )!important
    }
    .a82t7y svg path{
        @extend .sRU313;
    }
    .a142pz svg path{
        @extend .sRU313;
    }
    .j56448 svg path{
        @extend .sRU313;
    }
    .x3q2c6{
        background: map-get( $Color, "Default" )!important;
        color: map-get( $Color, "White" )!important;
    }
}